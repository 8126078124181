import React from "react";
import { DefaultTheme, ThemeProvider } from "styled-components";
import { GlobalStyle } from "./globalStyle";

const theme: DefaultTheme = {
  colors: {
    primary: "#39B54A",
    black: "#000",
    blackLight: "#323232",
    lightGrey: "#F5F5F5",
  },
  typography: {
    fontFamily: "Jura, sans-serif",
    fontSize: "16px",
    bodySmall: "14px",
    h1: {
      fontSize: "80px",
      fontFamily: "Poppins",
      fontWeight: 600,
      marginBottom: "30px",
      "@media only screen and (max-width: 991px)": {
        fontSize: "60px",
      },
      "@media only screen and (max-width: 767px)": {
        fontFamily: "Jura",
        fontSize: "30px",
        fontWeight: 700,
      },
    },
    h2: {
      fontSize: "36px",
      fontWeight: 600,
      marginBottom: "30px",
      "@media only screen and (max-width: 991px)": {
        fontSize: "31px",
        marginBottom: "15px",
      },
      "@media only screen and (max-width: 568px)": {
        fontSize: "12px",
        marginBottom: "15px",
        fontWeight: 700,
      },
    },
    h3: {
      fontSize: "28px",
      fontWeight: 600,
      marginBottom: "30px",
      "@media only screen and (max-width: 767px)": {
        fontSize: "26px",
      },
      "@media only screen and (max-width: 480px)": {
        fontSize: "22px",
      },
    },
    h4: {
      fontSize: "20px",
      fontWeight: 600,
      marginBottom: "30px",
    },
    h5: {
      fontSize: "18px",
      fontWeight: 500,
      marginBottom: "17px",
    },
    // h6: {
    //     fontSize: "80px",
    //     fontWeight: 600,
    //     marginBottom: "30px"
    // },

    strong: {
      fontWeight: 700,
    },
  },
};

export type ITheme = typeof theme;

type ThemeProps = {
  children: React.ReactNode;
};

const Theme: React.FC<ThemeProps> = ({ children }) => {
  return (
    <ThemeProvider theme={theme as ITheme}>
      <GlobalStyle />
      {children}
    </ThemeProvider>
  );
};

export default Theme;
