import * as React from 'react';
import type { GatsbyBrowser } from 'gatsby';
import Layout from './src/components/layout';
import { ToastContainer } from 'react-toastify';
import 'slick-carousel/slick/slick.css';

import 'slick-carousel/slick/slick-theme.css';
import 'react-toastify/dist/ReactToastify.css';

export const wrapPageElement: GatsbyBrowser['wrapPageElement'] = ({
  element,
  props,
}) => {
  return (
    <>
      <ToastContainer />
      <Layout {...props}>{element}</Layout>
    </>
  );
};
