import { Link } from "gatsby";
import React from "react";
import appLogo from "../../images/logo.svg";

export const AppLogo = () => {
  return (
    <Link to="/">
      <img src={appLogo} alt="Brim" className="app-logo" />
    </Link>
  );
};
