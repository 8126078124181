import { createGlobalStyle } from "styled-components";

export const GlobalStyle = createGlobalStyle`

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
body{
    font-family: 'Jura', sans-serif;
    font-size: ${(props) => props.theme.typography.fontSize};
    @media only screen and (max-width: 991px) {
        font-size: 14px;
    }
    @media only screen and (max-width: 568px) {
        font-size: 8px;
    }
    line-height: normal;
    color: ${(props) => props.theme.colors.blackLight};
}
h1{
    ${(props) => props.theme.typography.h1};
}
h2{
    ${(props) => props.theme.typography.h2};
}
h3{
    ${(props) => props.theme.typography.h3};
}
h4{
    ${(props) => props.theme.typography.h4};
}
h5{
    ${(props) => props.theme.typography.h5};
}
strong{
    ${(props) => props.theme.typography.strong};
}
p{
    margin-bottom: 20px;
}
.section-subheading {
    font-size: 20px;
    margin-bottom: 50px;
    @media only screen and (max-width: 568px) {
        margin-bottom: 15px !important;
        font-size: 8px !important;
    }
}
.text-center {
    text-align: center;
}
.text-left {
    text-align: left;
}
.text-right{
    text-align: right;
}

`;
