import React, { ReactNode } from "react";
import Theme from "../style/theme";
import { Footer } from "./footer";

const Layout: React.FC<{ children: ReactNode }> = ({ children }) => {
    return (
        <Theme>
            {children}
            <Footer />
        </Theme>
    )
}

export default Layout;