import React, { FC, InputHTMLAttributes } from "react";
import { styled } from "styled-components";

export const Container = styled.div`
  max-width: 1340px;
  padding: 0 16px;
  margin: 0 auto;
  width: 100%;
  @media only screen and (max-width: 1200px) {
    max-width: 990px;
  }
  @media only screen and (max-width: 991px) {
    max-width: 768px;
  }
`;

export const FullWidthSection = styled.section`
  padding: 100px 0;
  &.text-center {
    text-align: center;
  }
  @media only screen and (max-width: 767px) {
    padding: 50px 0;
  }
`;

export const Input = styled.input`
  height: 54px;
  padding: 0 21px;
  width: 100%;
  background: ${(props) => props.theme.colors.lightGrey};
  border-radius: 10px;
  margin-bottom: 30px;
  border: none;
  outline: none;
  font-family: Poppins;
  font-size: 16px;
  color: ${(props) => props.theme.colors.blackLight};
  &::placeholder &::-webkit-input-placeholder,
  &:-ms-input-placeholder,
  &::-moz-placeholder {
    color: ${(props) => props.theme.colors.blackLight};
  }
  @media only screen and (max-width: 568px) {
    height: 21px;
    font-size: 8px;
    border-radius: 5px;
    margin-bottom: 15px;
    padding: 0 8px;
  }
`;

const CheckboxContainer = styled.label`
  display: inline-block;
  position: relative;
  cursor: pointer;
  width: 30px;
  height: 30px;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
    &:checked ~ .checkmark:after {
      display: block;
    }
    &:checked ~ .checkmark {
      background-color: ${(props) => props.theme.colors.primary} !important;
    }
  }
  &:hover input ~ .checkmark {
    background-color: #eee;
  }

  .checkmark {
    width: 30px;
    height: 30px;
    display: block;
    border-radius: 10px;
    background: #fff;
    position: relative;
    &:after {
      content: "";
      position: absolute;
      display: none;
    }
    &:after {
      left: 11px;
      top: 7px;
      width: 5px;
      height: 10px;
      border: solid white;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
      @media only screen and (max-width: 568px) {
        left: 6px;
        top: 3px;
        width: 2px;
        height: 7px;
        border-width: 0 1px 1px 0;
      }
    }
  }
  @media only screen and (max-width: 568px) {
    &,
    .checkmark {
      width: 15px;
      height: 15px;
    }
  }
`;

export const CheckBox: FC<InputHTMLAttributes<HTMLInputElement>> = (props) => {
  return (
    <CheckboxContainer>
      <input type="checkbox" {...props} />
      <span className="checkmark"></span>
    </CheckboxContainer>
  );
};
// export const CheckBox = styled.input.attrs({
//     type: "checkbox",
// })`
//     width: 30px;
//     height: 30px;
// `

export const Button = styled.button`
  background: ${(props) => props.theme.colors.primary};
  border-radius: 15px;
  padding: 13px 40px;
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  width: 100%;
  border: 0px;
  outline: none;
  height: 59px;
  color: #fff;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);

  @media only screen and (max-width: 568px) {
    height: 23px;
    padding: 2px 20px;
    font-family: Poppins;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
`;
